<template>
  <div class="layout-auth">
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: 'LayoutAuth',
  data() {
    return {
      interval: null,
    }
  },
}
</script>

<style lang="scss" scoped>
.layout-auth {
}
</style>
